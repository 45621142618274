<template>
  <div class="view-audios container py-5">
    <div class="row g-4">
      <div class="col-lg-8">
        <h2 class="mb-0 font-serif">Áudios</h2>
        <div class="text-secondary mb-4">
          {{ count }} áudio{{ count === 1 ? "" : "s" }} encontrado{{
            count === 1 ? "" : "s"
          }}
        </div>

        <action-dispatcher action="podcasts/getAll" :parameters="parameters">
          <button
            v-for="audio in podcasts"
            :key="audio.id"
            class="btn btn-dark text-start w-100 mb-4 p-3"
            @click="active = audio.id"
          >
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="mb-0">{{ audio.name }}</h5>
              <i
                v-if="active !== audio.id"
                class="fa-regular fa-angle-down fa-lg"
              ></i>
            </div>
            <small
              v-if="audio.category"
              class="text-secondary text-uppercase"
              >{{ audio.category.name }}</small
            >
            <audio-player
              v-if="active === audio.id"
              :audio="audio"
              class="mt-3"
            />
          </button>
        </action-dispatcher>

        <pagination
          v-model="page"
          :count="count"
          :limit="parameters.limit"
          class="mt-5"
        />
      </div>

      <div class="col-lg-4">
        <search-form placeholder="Pesquisar áudio..." route="podcasts" />

        <h6 class="mt-5 mb-4">Categorias</h6>
        <action-dispatcher action="podcasts/getCategories" class="categories">
          <router-link
            :to="{ query: {} }"
            class="btn"
            :class="{
              'btn-primary': !$route.query.category,
              'btn-dark': $route.query.category,
            }"
            >Todos</router-link
          >
          <router-link
            v-for="category in categories"
            :key="category.id"
            :to="{ query: { category: category.id } }"
            class="btn"
            :class="{
              'btn-primary': $route.query.category === '' + category.id,
              'btn-dark': $route.query.category !== '' + category.id,
            }"
          >
            {{ category.name }}
          </router-link>
        </action-dispatcher>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AudioPlayer from "@/components/AudioPlayer";
import SearchForm from "@/components/SearchForm";

export default {
  components: { AudioPlayer, SearchForm },
  data() {
    return {
      active: null,
      page: parseInt(this.$route.query.page || 1),
    };
  },
  computed: {
    ...mapState("podcasts", ["podcasts", "count", "categories"]),
    parameters() {
      const limit = 10;
      return {
        ...this.$route.query,
        limit,
        offset: limit * (this.page - 1),
      };
    },
  },
  watch: {
    page() {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.page,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.view-audios {
  .ratio {
    --bs-aspect-ratio: 141.4%;
  }

  .categories {
    .btn {
      display: block;
      margin-top: 16px;
      text-align: left;
      font-weight: normal;
      padding: 12px;
    }
  }
}
</style>
